import { auth } from './firebase';

export default {
  methods: {
    async checkLastSignInDate() {
      if (auth.currentUser) {
        try {
          const lastSignInTimestamp = auth.currentUser.metadata.lastSignInTime;
          const lastSignInDate = new Date(lastSignInTimestamp);

          const currentDate = new Date();
          const ninetyDaysAgo = new Date(currentDate);
          ninetyDaysAgo.setDate(currentDate.getDate() - 30);
          
          if (lastSignInDate < ninetyDaysAgo) {
            // User needs to re-sign in
            this.signOutAndRedirect(); // Call the method to sign out and redirect
          }
        } catch (error) {
          console.error('Error fetching user metadata:', error);
        }
      }
    },
    async signOutAndRedirect() {
      try {
        await auth.signOut();

        // Reset data properties when signing out
        this.$data.token = null; // Assuming 'token' is a data property

        // Redirect to the login page using Vue Router
        this.$router.push('/login');
      } catch (error) {
        console.error('Error signing out:', error);
        // Handle sign-out error, if needed
      }
    },
  },
};
