import { createRouter, createWebHistory } from 'vue-router'
import { getUserState } from '../firebase'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import ('../views/Home.vue'),
    meta: { requiresAuth: true, title: 'Home' }
  },
  {
    path: '/account',
    name: 'Account',
    component: () => import('../views/Account.vue'),
    meta: { requiresAuth: true, title: 'Account'}
  },
  {
    path: '/accountChangePassword',
    name: 'AccountChangePassword',
    component: () => import('../views/AccountChangePassword.vue'),
    meta: { requiresAuth: true, title: 'Account Update'}
  },
  {
    path: '/devices',
    name: 'Devices',
    component: () => import('../views/Devices.vue'),
    meta: { requiresAuth: true, title: 'Devices'}
  },
  {
    path: '/deviceDetails/:id',
    name: 'deviceDetails',
    component: () => import('../views/DeviceDetails.vue'),
    meta: { requiresAuth: true, title: 'Device Details'}
  },
  {
    path: '/deviceEdit/:id&:onPowerRestore',
    name: 'deviceEdit',
    component: () => import('../views/DeviceEdit.vue'),
    meta: { requiresAuth: true, title: 'Device Edit' }
  },
  {
    path: '/activateDevice',
    name: 'ActivateDevice',
    component: () => import('../views/ActivateDevice.vue'),
    meta: { requiresAuth: true, title: 'Activate Device' }
  },
  {
    path: '/cloudapi',
    name: 'CloudAPI',
    component: () => import('../views/CloudAPI.vue'),
    meta: { requiresAuth: true, title: 'Cloud API'  }
  },
  {
    path: '/billing',
    name: 'Billing',
    component: () => import('../views/Billing.vue'),
    meta: { requiresAuth: true, title: 'Billing' }
  },
  {
    path: '/billingEmailEdit/:billingEmailSnap',
    name: 'BillingEmailEdit' ,
    component: () => import('../views/BillingEmailEdit.vue'),
    meta: { requiresAuth: true, title: 'Billing Update'}
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/Login.vue'),
    meta: { requiresUnauth: true, title: 'Login'}
  },
  {
    path: '/signup',
    name: 'SignUp',
    component: () => import('../views/SignUp.vue'),
    meta: { requiresUnauth: true, title: 'Sign Up'}
  },
  {
    path: '/reset',
    name: 'Reset',
    component: () => import('../views/Reset.vue'),
    meta: { requiresUnauth: true, title: 'Password Reset'}
  },
  {
    path: '/store-link',
    name: 'StoreLink',
    beforeEnter: (to, from, next) => {
      window.open('https://www.logmor.com', '_blank');
      next(false); // Prevent Vue Router from continuing with the navigation
    },
    meta: { requiresAuth: true, title: 'Store Link' }
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach(async (to, from, next) => {
  
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth)
  const requiresUnauth = to.matched.some(record => record.meta.requiresUnauth)

  const isAuth = await getUserState()

  if (requiresAuth && !isAuth) next('/login')
  else if (requiresUnauth && isAuth) next('/')
  else next()
})

export default router