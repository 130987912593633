import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { ref, computed, onMounted, onUnmounted } from 'vue';
import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getAnalytics } from 'firebase/analytics'; // Import Firebase Analytics

// Your web app's Firebase configuration
export const config = {
  apiKey: "AIzaSyDMjkur5faGOm4f5uqCcFZQQIbvcPkgd5Q",
  authDomain: "logmor-admin-panel.firebaseapp.com",
  projectId: "logmor-admin-panel",
  storageBucket: "logmor-admin-panel.appspot.com",
  messagingSenderId: "119319672569",
  appId: "1:119319672569:web:5b8eb5f6389413fcaa1428",
  measurementId: "G-WFZC6TZ4LL"
};

// Initialize Firebase app
const firebaseApp = initializeApp(config);

// Initialize Firestore
const db = getFirestore(firebaseApp);

// Initialize Analytics
const analytics = getAnalytics(firebaseApp);

// Export Firestore and Analytics instances
export { db, analytics };

// Export Auth and Firebase App Instances
const auth = getAuth(firebaseApp);
export { auth, firebaseApp };

// Function to get the user's authentication state
export const getUserState = () =>
  new Promise((resolve, reject) =>
    onAuthStateChanged(getAuth(), resolve, reject)
  );

// Use Composition API to manage authentication state
export const useAuthState = () => {
  const user = ref(null);
  const error = ref(null);

  const auth = getAuth();
  let unsubscribe;

  onMounted(() => {
    unsubscribe = onAuthStateChanged(
      auth,
      (u) => (user.value = u),
      (e) => (error.value = e)
    );
  });

  onUnmounted(() => unsubscribe());

  const isAuthenticated = computed(() => user.value != null);

  return { user, error, isAuthenticated };
};




